import  React from 'react'

export default function WarningIcon({ size = '32px' }: {size?: string}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M10 15.625C6.89813 15.625 4.375 13.1019 4.375 10C4.375 6.89813 6.89813 4.375 10 4.375C13.1019 4.375 15.625 6.89813 15.625 10C15.625 13.1019 13.1019 15.625 10 15.625ZM10 3.125C6.20937 3.125 3.125 6.20937 3.125 10C3.125 13.7906 6.20937 16.875 10 16.875C13.7906 16.875 16.875 13.7906 16.875 10C16.875 6.20937 13.7906 3.125 10 3.125Z" fill="#3A3A3A"/>
      <path d="M9.40247 6.94471C9.35057 6.94471 9.31597 6.97932 9.31597 7.03122L9.46303 10.5172C9.46303 10.5778 9.48898 10.6037 9.54954 10.6037H10.4498C10.5104 10.6037 10.5364 10.5778 10.5364 10.5172L10.6834 7.03122C10.6834 6.97932 10.6488 6.94471 10.5969 6.94471H9.40247Z" fill="#3A3A3A"/>
      <path d="M10.7186 12.3459C10.7186 11.9647 10.4066 11.6359 9.99938 11.6359C9.59279 11.6359 9.28136 11.9647 9.28136 12.3459C9.28136 12.7266 9.59279 13.0553 9.99938 13.0553C10.4066 13.0553 10.7186 12.7266 10.7186 12.3459Z" fill="#3A3A3A"/>
    </svg>
  )
}
