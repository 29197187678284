import React from 'react'
import './InputCheckbox.scss'

interface InputCheckboxProps {
  checked: boolean
  onCheckChanged: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  id: string
  name?: string
  label?: string
  className?: string
}

const InputCheckbox = ({ checked, onCheckChanged, disabled = false, id, name, label, className }: InputCheckboxProps) => {
  return <div className='input-checkbox-container'>
    {/* If we have a label provided, the checkbox will be contained inside it. This is so that the clickable area can be a larger size if needed (if padding is provided in className for example). */}
    {label
      ? <label htmlFor={id} className={`flex grow cursor-pointer select-none${className ? ' ' + className : ''}`}>{label}
        <input id={id} name={name} className='input-checkbox ml-auto' type={'checkbox'} checked={checked} onChange={onCheckChanged} disabled={disabled}></input>
      </label>
      : <input id={id} name={name} className={`input-checkbox${className ? ' ' + className : ''}`} type={'checkbox'} checked={checked} onChange={onCheckChanged} disabled={disabled}></input>
    }
  </div>
}

export default InputCheckbox