import React, { useEffect, useState } from "react"
import './Header.scss'
import { Link, useLocation, useNavigate } from "react-router-dom"
import Logout from '../Logout'
import { useUserContext } from '../../contexts/user-context'
import IcaLogo from "../Icons/IcaLogo"
import SelectStore from "../SelectStore"
import { isAdmin } from "../../utils/userUtils"
import { useStoreContext } from "../../contexts/store-context"
import { get } from "../../utils/http"
import StoreGroceryStock from "../../types/StoreGroceryStock"
import config from "../../config"
import { useDeviceContext } from "../../contexts/device-context"
import MenuIcon from "../Icons/MenuIcon"
import CloseIcon from "../Icons/CloseIcon"
import { getTodayText } from "../../utils/dateUtils"

export interface MenuLink {
  displayText: string
  to: string
}

const Header = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { userState } = useUserContext()
  const { storeState } = useStoreContext()
  const { deviceState } = useDeviceContext()
  const [displayGroceries, setDisplayGroceries] = useState(false)
  const [displayMobileMenu, setDisplayMobileMenu] = useState(false)

  const activeStyle = (link: string) => {
    return location.pathname.includes(link) ? ' active' : ''
  }

  const LogoutButton = () => {
    return (
      <div className="tooltip">
        <div className="tooltip-text">{userState.user.displayName}</div>
        <Logout />
      </div>
    )
  }

  const getMenuLinks = (): Array<MenuLink> => {
    const links = [      
      { to: '/overview', displayText: `Idag ${getTodayText()}` },
      { to: '/live-feed', displayText: 'Live' },
      { to: '/sessions', displayText: 'Kundvarv' },
      { to: '/customers', displayText: 'Kunder' },
    ]

    if (displayGroceries) {
      links.push({ to: '/groceries', displayText: 'Sortiment' })
    }

    return links
  }

  useEffect(() => {
    async function setup() {
      const groceries = await get<Array<StoreGroceryStock>>(`${config.GROCERY_STOCK_API_BASE_URL}/v1/grocerystock/${storeState.selectedStore?.id}`)
      if(groceries.length === 0 && location.pathname === '/groceries') {
        navigate('/overview')
      }
      setDisplayGroceries(groceries.length > 0)
    }

    if(storeState.selectedStore?.id) {
      setup()
    }
  }, [storeState.selectedStore?.id])

  return (
    <>
      {deviceState.isMobile
        ? <>
          <header className="header-mobile">
            <div className="header-content-container flex items-center">
              <div className="header-menu-button flex items-center justify-center mr-4" onClick={() => setDisplayMobileMenu(!displayMobileMenu)}>
                <MenuIcon size="20px" />
              </div>
              <Link to="/overview">
                <IcaLogo size="112px" />
              </Link>
              <div className="items-center ml-auto">
                <LogoutButton />
              </div>
            </div>
            <div className="header-mobile-store-select-container pl-5">
              <SelectStore className="header-mobile-store" />
            </div>
          </header>
          {displayMobileMenu && <div className="header-mobile-menu">      
            <div className="header-mobile header-content-container flex items-center">
              <div className="close-button mr-4" onClick={() => setDisplayMobileMenu(!displayMobileMenu)}>
                <CloseIcon size="20px" />
              </div>
              <Link to="/overview" onClick={() => setDisplayMobileMenu(false)}>
                <IcaLogo size="112px" />
              </Link>
            </div>
            <div className="header-mobile-link-container header-nav w-full border-top">
              {getMenuLinks().map((link) => 
                <Link key={link.to} to={link.to} className={`header-mobile-link select-none block${activeStyle(link.to)}`} onClick={() => setDisplayMobileMenu(false)}>
                  <div>
                    {link.displayText}
                  </div>
                </Link>
              )}
            </div>
          </div>}
        </>
        : <header className="header-full border-bottom">
          <div className="header-content-container mx-auto grid grid-cols-3">
            <div className="flex items-center">
              <Link to="/overview">
                <IcaLogo />
              </Link>
              <div className="header-store-select-container ml-5">
                <SelectStore />
              </div>
            </div>
            {(isAdmin(userState.user.roles) || storeState.availableStores?.length > 0)
              ? <div className="header-nav flex items-center mx-auto">
                {getMenuLinks().map((link) => 
                  <Link key={link.to} to={link.to} className={`select-none${activeStyle(link.to)}`}>
                    {link.displayText}
                  </Link>
                )}
              </div>
              : <div></div>
            }
            <div className="header-content-right flex items-center ml-auto">
              <LogoutButton />
            </div>
          </div>
        </header>
      }
    </>
  )
}

export default Header