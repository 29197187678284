import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import config from '../../config'
import { useStoreContext } from '../../contexts/store-context'
import { SessionBrief } from '../../types/SessionBrief'
import { get, post } from '../../utils/http'
import Modal from '../Modal/Modal'
import SessionInfo from '../SessionInfo/SessionInfo'
import { useNavigate  } from "react-router-dom"
import SpinnerIcon from '../Icons/SpinnerIcon'
import NavigateBack from '../NavigateBack/NavigateBack'
import UserIcon from '../Icons/UserIcon'
import '../../styles/shared/list.scss'
import './CustomerInfo.scss'
import InputCheckbox from '../InputCheckbox/InputCheckbox'
import CustomerBannedInfo from '../CustomerBannedInfo/CustomerBannedInfo'
import SessionListItem from '../SessionListItem/SessionListItem'
import AcrFlag from '../AcrFlag/AcrFlag'

interface Customer {
  banned: boolean
  bannedAt: string
  id: string
  sessions: Array<SessionBrief>
}

interface DoorBlock {  
  customerId: string
  createdById: string
  createdByEmail: string
  createdAt: string
}

export default function CustomerInfo() {
  const [customer, setCustomer] = useState<Customer | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { id, acr, sessionId } = useParams()
  const { storeState } = useStoreContext()
  const [isBanned, setIsBanned] = useState(false)
  const [banError, setBanError] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const backUrl = useRef(location?.state?.previousUrl ?? null)

  const fetchCustomerInfo = async () => {
    setLoading(true)
    setCustomer(null)
    setError(false)
    try {
      const result = await get<Customer>(`${config.STORE_API_BASE_URL}/v2/monitor/customer/${storeState.selectedStore?.id}/${id}/${acr}`)
      result.sessions = result.sessions.sort((a, b) => {
        return new Date(b.latestActivity).getTime() - new Date(a.latestActivity).getTime()
      })
      setCustomer(result)
      setIsBanned(result.banned)
    } catch (error) {
      setError(true)
    }
    setLoading(false)
  }

  const onModalClose = () => {
    navigate(`/customers/${id}/${acr}`)
  }

  const toggleBanCustomer = async (banned: boolean) => {
    if(banned) {
      setBanError(false)
      try {
        const response: DoorBlock = await post(`${config.STORE_API_BASE_URL}/v2/monitor/customer/${id}/${acr}/ban`, null)
        if(customer !== null) {
          const newCustomer = customer
          newCustomer.banned = response !== null
          newCustomer.bannedAt = response.createdAt
          setCustomer(newCustomer)
        }
        setIsBanned(true)
      } catch (error) {
        setIsBanned(false)
        setBanError(true)
      }
    } else {
      try {
        await post(`${config.STORE_API_BASE_URL}/v2/monitor/customer/${id}/${acr}/unban`,  null)
        setIsBanned(false)
      } catch (error) {
        setIsBanned(true)
        setBanError(true)
      }
    }
  }

  useEffect(() => {
    if (storeState.selectedStore?.id) {
      fetchCustomerInfo()
    }
  }, [storeState.selectedStore?.id])

  return (
    <div className='customer-info'>
      <NavigateBack backUrl={backUrl.current} />
      {customer && 
      <div className='my-4'>
        <div className='customer-info__list-item list p-4'>
          <div className='list-item__container'>
            <div className='list-item__left flex items-center'>
              <UserIcon />
              <div className='pl-4'>
                <div className='customer-info__text-top mb-1 flex items-center'>Kund-id: {customer.id}{acr && <div className='ml-3'><AcrFlag acr={acr} width={18}/></div>}</div>
                <div className='customer-info__text-bottom mt-1'>Antal kundvarv: {customer.sessions.length}</div>
              </div>
            </div>
            <div className='customer-info__ban-container list-item__right flex justify-end items-center'>
              {isBanned ? <CustomerBannedInfo bannedAt={customer?.bannedAt} /> : <label className='customer-info__ban-label my-0 ml-0 mr-2'>Spärra kund</label>}              
              <div className='ml-4'>
                <InputCheckbox id={'ban-checkbox'} checked={isBanned} onCheckChanged={(e) => toggleBanCustomer(e.target.checked)} />
              </div>
            </div>
          </div>
        </div>
        {banError && <p className='error px-3 text-right'>Det gick inte att spärra kunden</p>} 
      </div>
      }
      <div className='list'>
        {loading &&
        <div className='grid place-content-center'>
          <SpinnerIcon />
        </div>
        }
        {customer && customer.sessions.map((session) => 
          <div className='list-item' key={session.id}>
            <Link to={`/customers/${id}/${acr}/${session.id}`}>
              <SessionListItem session={session}></SessionListItem>
            </Link>
          </div>
        )}
        {!loading && !error && customer && customer.sessions.length === 0 && <div className='grid place-items-center'>Ingen kundhistorik kunde hittas för angiven butik och tidsintervall.</div>}
        {!loading && error && <p className='error text-center'>Någonting gick fel. Försök igen genom att ladda om sidan.</p>}
      </div>
      {sessionId && 
      <div>
        <Modal title='Kundvarv' show={!!sessionId} onClose={onModalClose}>
          <SessionInfo />
        </Modal>
      </div>
      }
    </div>
  )
}
