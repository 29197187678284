import React from 'react'
import RequireAuth from './RequireAuth'
import PageContainer from './PageContainer/PageContainer'
import { StoreProvider } from '../contexts/store-context'
import { FilterProvider } from '../contexts/filter-context'
import { DeviceProvider } from '../contexts/device-context'

function AuthenticatedPage({ children }: { children: JSX.Element }) {
  return (
    <RequireAuth>
      <DeviceProvider>
        <StoreProvider>
          <FilterProvider>
            <PageContainer>
              {children}
            </PageContainer>
          </FilterProvider>
        </StoreProvider>
      </DeviceProvider>
    </RequireAuth>
  )
}

export default AuthenticatedPage