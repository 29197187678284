import React, { useEffect, useState } from 'react'
import useSignalRFeed from '../../hooks/useSignalrFeed'
import "./Feed.scss"
import SpinnerIcon from '../Icons/SpinnerIcon'
import StoreEventFeedItem from '../../types/StoreEventFeedItem'
import { WebSocketInvocation } from '../../hooks/webSocketInvocation'
import filterAndSortFeedItems from './filterAndSortFeedItems'
import config from '../../config'
import { FeedColumn } from '../FeedColumn/FeedColumn'
import { useNavigate, useParams } from 'react-router-dom'
import Modal from '../Modal/Modal'
import SessionInfo from '../SessionInfo/SessionInfo'

export default function Feed() {
  const navigate = useNavigate()
  const { sessionId } = useParams()

  const invocations: Array<WebSocketInvocation<StoreEventFeedItem>> = [{
    name: "UpsertSessionState",
    action: (data: StoreEventFeedItem) => {
      setFeed((prevFeedState: Array<StoreEventFeedItem>) => {
        return filterAndSortFeedItems(prevFeedState, data)
      })
    }
  }]

  const { initialFeed, loading, error } = useSignalRFeed<StoreEventFeedItem>(config.EVENT_FEED_SIGNALR_URL, `${config.EVENT_API_BASE_URL}/v1/storeevent`, invocations)

  const [feed, setFeed] = useState<Array<StoreEventFeedItem>>([])
  
  useEffect(() => {
    setFeed(initialFeed)
  }, [initialFeed])

  return (
    <>
      <div className="feed feed-table-body">
        {loading && <div className='feed-spinner'><SpinnerIcon /> </div>}
        {!loading && error && <p className='error'>Någonting gick fel. Försök igen genom att ladda om sidan.</p>}
        <div className="feed-grid flex flex-col md:flex-row">
          <FeedColumn title={'Öppnat dörr'} items={feed.filter((item) => item.sessionState === 'Visiting')} />
          <FeedColumn title={'Handlar'} items={feed.filter((item) => item.sessionState === 'Shopping')} />
          <FeedColumn title={'Betalar'} items={feed.filter((item) => item.sessionState === 'Paying')} />
          <FeedColumn title={'Dörr ut'} items={feed.filter((item) => item.sessionState === 'Exiting')} />
        </div>
      </div>
      {sessionId &&
      <Modal title='Kundvarv' show={!!sessionId} onClose={() => navigate('/live-feed')}>
        <SessionInfo />
      </Modal>
      }</>
  )
}
