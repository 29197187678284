import React, { ReactNode, useContext } from "react"
import { useStoreContext } from "./store-context"

interface FilterBase {
  [key: string]: any;
  storeId: string | null
  paymentMissing: boolean
  removedItems: boolean
  accessDenied: boolean
  multiplePayments: boolean
  minItemCount: number | null
  maxItemCount: number | null
  minSessionDurationMinutes: number | null
  maxSessionDurationMinutes: number | null
  minPriceKr: number | null
  maxPriceKr: number | null
  minCustomerWeighedItemGrams: number | null
  maxCustomerWeighedItemGrams: number | null
}

export interface Filter extends FilterBase {
  from: Date
  to: Date
}

export interface FilterPayload extends FilterBase {
  from: string
  to: string
}

export interface FilterContextState {
  filterState: {
    filter: Filter,
  },
  filterReducer: {
    setFilter: (filter: Filter) => void,
    setPropertyValue: (propertyName: string, value: string | number | boolean | Date | null | undefined) => void,
  }
}

const FilterContext = React.createContext<FilterContextState | undefined>(undefined)

export function FilterProvider({ children }: {children : ReactNode}) {
  const [filter, setFilter] = React.useState<Filter>(getDefaultFilter())
  const { storeState } = useStoreContext()

  const setPropertyValue = (propertyName: string, value: string | number | boolean | Date | null | undefined) => {
    const newFilter = { ...filter }
    newFilter[propertyName] = value
    setFilter(newFilter)
  }

  React.useEffect(() => {
    setPropertyValue('storeId', storeState.selectedStore?.id)
  }, [storeState.selectedStore])

  const state: FilterContextState = React.useMemo(() => {
    return {
      filterState: {
        filter,
      },
      filterReducer: {
        setFilter,
        setPropertyValue,
      }
    }
  }, [filter])

  return (
    <FilterContext.Provider value={state}>
      {children}
    </FilterContext.Provider>
  )
}

export const getDefaultFilter = (): Filter => {
  return {
    storeId: null,
    from: new Date(new Date().setHours(0, 0, 0, 0)),
    to: new Date(new Date().setHours(23, 59, 59, 999)),
    paymentMissing: false,
    removedItems: false,
    accessDenied: false,
    multiplePayments: false,
    minItemCount: null,
    maxItemCount: null,
    minSessionDurationMinutes: null,
    maxSessionDurationMinutes: null,
    minPriceKr: null,
    maxPriceKr: null,
    minCustomerWeighedItemGrams: null,
    maxCustomerWeighedItemGrams: null,
  } as Filter
}

export function getFilterPayload(filter: Filter): FilterPayload {
  const filterPayload: FilterPayload = {
    ...filter,
    from: filter.from.toISOString(),
    to: filter.to.toISOString(),
  }
  return filterPayload
}

export function useFilterContext() {
  const context = useContext(FilterContext)
  if (!context) {
    throw new Error('useFilterContext must be used within a FilterProvider')
  }
  return context
}
