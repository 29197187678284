import React, { ReactNode } from 'react'
import ReactDOM from 'react-dom'
import "./SidebarModal.scss"
import ScrollTrap from '../ScrollTrap/ScrollTrap'

export interface ISidebarModalProps {
  show: boolean
  children: Array<ReactNode>
}

export default function SidebarModal ({ show, children }: ISidebarModalProps) {
  const anchorElement = document.getElementsByClassName('page-container')[0]

  return (
    show && anchorElement ?
      ReactDOM.createPortal(
        <div className='sidebar-modal place-content-center' id="modal-root">
          <ScrollTrap>
            <div className='sidebar-modal-body'>
              {children}
            </div>
          </ScrollTrap>
        </div>, anchorElement
      ) : null
  )
}
