import React from 'react'
import WarningIcon from '../Icons/WarningIcon'

export interface ICustomerBannedInfoProps {
  bannedAt?: string
}

export default function CustomerBannedInfo({ bannedAt } : ICustomerBannedInfoProps) {
  return (      
    <div className='session-tags__tag flex'>
      <div className='mr-1'><WarningIcon size='20px' /></div>
      Spärrad{bannedAt && <>: {bannedAt}</>}
    </div>
  )
}
