import React from 'react'
import { dateStringToDisplayDate } from '../../utils/dateUtils'
import StoreEventFeedItem from '../../types/StoreEventFeedItem'
import "./FeedColumn.scss"
import UserIcon from '../Icons/UserIcon'
import { Link } from 'react-router-dom'

export interface IFeedColumn {
  title: string
  items: StoreEventFeedItem[]
}

export function FeedColumn({ title, items } : IFeedColumn) {
  return (
    <div className="feed-column flex-1 flex flex-col">
      <div className='feed-column-title text-center select-none'>{title}</div>
      <div className="feed-column-content flex-1 mt-4">
        {items.map((item, i) =>
          <div className='feed-column-item border' key={i}>
            <Link className='inline-block w-full pt-3 pb-2' to={`/live-feed/${item.id}`}>
              <div className="flex items-center">
                <div className='ml-4'>
                  <UserIcon size='24px' />
                </div>
                <div className='ml-4'>
                  <div className='feed-column-item-session'>{item.id.substring(0, 23)}...</div>
                  <div className='feed-column-item-timestamp'>{dateStringToDisplayDate(item.latestActivity)}</div>
                </div>
              </div>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}