import React, { ReactElement } from 'react'

export interface ITextInputProps {
  className?: string
  id?: string
  name?: string
  placeholder?: string
  ariaLabel?: string
  type?: 'text' | 'password'
  autoComplete?: string 
  value: string
  readonly?: boolean
  icon?: ReactElement
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function InputText({ className, id, name, placeholder, ariaLabel, type = 'text', autoComplete, value, readonly = false, icon, onClick, onChange }: ITextInputProps) {
  return (
    <div className='relative'>
      <input 
        className={`${className} text-input`}
        id={id} name={name}
        placeholder={placeholder}
        type={type} aria-label={ariaLabel}
        value={value}
        readOnly={readonly}
        autoComplete={autoComplete}
        onClick={onClick} 
        onChange={onChange} />
      {icon && <div className='text-input-icon flex items-center top-0 right-0 mr-3 pointer-events-none'>{icon}</div>}
    </div>
  )
}
