import React from 'react'
import CustomerInfo from '../components/CustomerInfo/CustomerInfo'

export default function Customer() {
  return (
    <div className='md:pt-5'>
      <CustomerInfo />
    </div>
  )
}
