import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import config from '../../config'
import { dateStringToDisplayDate } from '../../utils/dateUtils'
import { post } from '../../utils/http'
import Filter from '../SessionFilter/SessionFilter'
import SpinnerIcon from '../Icons/SpinnerIcon'
import UserIcon from '../Icons/UserIcon'
import "./CustomerList.scss"
import CustomerBannedInfo from '../CustomerBannedInfo/CustomerBannedInfo'
import AcrFlag from '../AcrFlag/AcrFlag'
import { getFilterPayload, useFilterContext } from '../../contexts/filter-context'
import ArrowIconMinimal from '../Icons/ArrowIconMinimal'

interface CustomerBrief {
  id: string
  acr: string
  latestActivity: string
  banned: boolean
  bannedAt: string
}

interface CustomerRequestBody {
  from: string
  to: string
}

export default function Customer() {
  const [customerList, setCustomerList] = useState<Array<CustomerBrief>>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const { filterState } = useFilterContext()

  useEffect(() => {
    const fetchCustomers = async () => {
      if (!filterState.filter?.storeId) {
        return
      }
      else if (filterState.filter.from > filterState.filter.to) {
        return
      }

      setCustomerList([])
      setError(false)
      setLoading(true)
      try {
        const filterPayload: CustomerRequestBody = getFilterPayload(filterState.filter)
        const result = await post<CustomerRequestBody, Array<CustomerBrief>>(`${config.STORE_API_BASE_URL}/v1/monitor/customers/${filterState.filter.storeId}`, filterPayload)
        setCustomerList(result)
      } catch (error) {
        setError(true)
      }
      setLoading(false)
    }
    fetchCustomers()
  }, [filterState.filter])

  return (
    <div className='list-filter__container'>
      <div className='list list-filter__left customer-info'>
        {!loading && !error && customerList.length !== 0 && <div className='list-filter__count mb-1'>Visar {customerList.length} kunder</div>}
        {loading &&
        <div className='grid place-content-center'>
          <SpinnerIcon />
        </div>
        }
        {customerList && customerList.map((c) =>
          <div className='list-item customer-info__list-item' key={`${c.id}-${c.acr}`}>
            <Link to={`/customers/${c.id}/${c.acr}${location.search}`} state={{ previousUrl: `${location.pathname}${location.search}` }}>
              <div className='list-item__container'>
                <div className='list-item__left flex items-center'>
                  <UserIcon />
                  <div className='pl-4'>
                    <div className='customer-info__text-top mb-1 flex items-center'>Kund-id: {c.id}{c.acr && <div className='ml-3'><AcrFlag acr={c.acr} width={18}/></div>}</div>
                    <div className='customer-info__text-bottom mt-1'>
                      <span>Senaste aktivitet: </span>
                      <span className='whitespace-nowrap'>{dateStringToDisplayDate(c.latestActivity)}</span>
                    </div>
                  </div>
                </div>
                <div className='list-item__right flex'>
                  {c.banned && 
                  <div className='customer-info__banned-info flex self-center mx-0 md:mx-4 mt-2 md:mt-0'>
                    <CustomerBannedInfo bannedAt={c.bannedAt} />
                  </div>}
                </div>
                <div className='list-item__arrow flex items-center'>
                  <ArrowIconMinimal direction='right'></ArrowIconMinimal>
                </div>
              </div>
            </Link>
          </div>
        )}
        {!loading && error && <p className='error text-center'>Någonting gick fel. Försök igen genom att ladda om sidan.</p>}
        {!loading && !error && customerList.length === 0 && <div className='list-filter__placeholder'>Inga kunder hittades för angiven butik och tidsintervall.</div>}
      </div>
      <div className='list-filter__right'>
        <Filter />
      </div>
    </div>
  )
}
