export function dateStringToDisplayDate (dateString: string): string {
  return new Date(dateString).toLocaleString('sv-SE')
}

export function getSessionDateTimeSpan(fromDateStr: string, toDateStr: string, includeSeconds: boolean = true) {
  let secondFormat: "2-digit" | undefined = undefined
  if (includeSeconds) {
    secondFormat = "2-digit"
  }

  const fromDate: Date = new Date(fromDateStr)
  let formattedFromDate: string
  if (fromDate.getUTCFullYear() != new Date().getUTCFullYear()) {
    // Display year if it differs from current year
    formattedFromDate = fromDate.toLocaleString('sv-SE', { year: "numeric", month: 'short', day: "numeric", hour: "2-digit", minute: "2-digit", second: secondFormat })
  }
  else {
    formattedFromDate = fromDate.toLocaleString('sv-SE', { month: 'short', day: "numeric", hour: "2-digit", minute: "2-digit", second: secondFormat })
  }

  const toDate: Date = new Date(toDateStr)
  let formattedToDate: string
  if (fromDate.toDateString() != toDate.toDateString()) {
    formattedToDate = toDate.toLocaleString('sv-SE', { month: 'short', day: "numeric", hour: "2-digit", minute: "2-digit", second: secondFormat })
  }
  else {
    // If date is the same; only display date on fromDate
    formattedToDate = toDate.toLocaleString('sv-SE', { hour: "2-digit", minute: "2-digit", second: secondFormat })
  }

  return `${formattedFromDate}–${formattedToDate}`
}

export function dateStringToRestockDisplayDate (dateString: string): string {
  const date = new Date(dateString)
  return date.toLocaleString('sv-SE', { day: "numeric", month: 'short' })
}

export function differenceInMinutes(date1str: string, date2str: string): number {
  const date1: Date = new Date(date1str)
  const date2: Date = new Date(date2str)
  return Math.floor(Math.abs(date1.getTime() - date2.getTime()) / 60000) // 60000 = milliseconds to minutes factor
}

export function getTodayText(): string {
  const today = new Date()
  return `${today.getDate()}/${today.getMonth() + 1}`
}