import React from 'react'
import { SessionBrief } from '../../types/SessionBrief'
import { differenceInMinutes, getSessionDateTimeSpan } from '../../utils/dateUtils'
import displayPrice from '../../utils/displayPrice'
import AcrFlag from '../AcrFlag/AcrFlag'
import SessionTags from '../SessionTags/SessionTags'
import ArrowIconMinimal from '../Icons/ArrowIconMinimal'

export interface ISessionListItem {
  session: SessionBrief,
}

export default function SessionListItem({ session } : ISessionListItem) {
  return (
    <div className='list-item__container'>
      <div className='list-item__left'>
        <div className='list-item__date flex items-center'>
          {session.isShopping 
            ? <><div className='list-item__is-shopping-icon mr-2'></div>Pågående kundvarv</>
            : <div>{getSessionDateTimeSpan(session.firstActivity, session.latestActivity, false)}</div>}
          <span className='ml-1'>({differenceInMinutes(session.firstActivity, session.latestActivity)} min)</span>
        </div>
        <div className='my-1 list-item__session_items'>{session.itemCount} varor{session.orderNumber && `, ${displayPrice(session.totalPrice)} kr`}</div>
        <div className='flex flex-col lg:flex-row'>
          {session.paymentInternalDisplayName && <div className='list-item__payment_method mr-2 mb-1 lg:mb-0'>{session.paymentInternalDisplayName}</div>}
          <div className='flex items-center'>
            <div className='list-item__id'>{session.id}</div>
            {session.acr && <span className='ml-2'><AcrFlag acr={session.acr} width={18}/></span>}
          </div>
        </div>
      </div>
      <div className='list-item__right flex items-center'>
        <SessionTags containerClassName='flex flex-wrap md:justify-end'
          accessDeniedReason={session.accessDeniedReason}
          hasCustomerWeighedItems={session.hasCustomerWeighedItems}
          hasMultipleCompletedPayments={session.hasMultipleCompletedPayments}
          hasRemovedItems={session.hasRemovedItems}
          isMissingPayment={session.isMissingPayment}>
        </SessionTags>
      </div>
      <div className='list-item__arrow flex items-center'>
        <ArrowIconMinimal direction='right'></ArrowIconMinimal>
      </div>
    </div>
  )
}
