import React from 'react'
import './SessionTags.scss'

export interface ISessionTags {
  containerClassName?: string
  accessDeniedReason: string | undefined
  hasCustomerWeighedItems: boolean
  hasMultipleCompletedPayments: boolean
  hasRemovedItems: boolean
  isMissingPayment: boolean
}

export default function SessionTags({ containerClassName, accessDeniedReason, hasCustomerWeighedItems, hasMultipleCompletedPayments, hasRemovedItems, isMissingPayment } : ISessionTags) {
  const accessDeniedReasonTranslation = {
    AgeAccessDenied: ' - Ålderskrav',
    DoorAccessDenied: '',
    NotStammisAccessDenied: ' - Icke-stammis',
  }

  return (
    <div className={`session-tags__container${containerClassName ? ' ' + containerClassName : ''}`}>
      {accessDeniedReason && <div className='session-tags__tag'>Spärrad{accessDeniedReasonTranslation[accessDeniedReason as keyof typeof accessDeniedReasonTranslation]}</div>}
      {hasCustomerWeighedItems && <div className='session-tags__tag'>Viktvara</div>}
      {hasMultipleCompletedPayments && <div className='session-tags__tag'>Flera betalningar</div>}
      {hasRemovedItems && <div className='session-tags__tag'>Borttagning av vara</div>}
      {isMissingPayment && <div className='session-tags__tag'>Saknar Betalning</div>}
    </div>
  )
}
