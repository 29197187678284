import StoreGroceryStock from "../../types/StoreGroceryStock"
import { isStockBelowRatio } from "../../utils/groceryStockUtils"

export interface FilterOption {
  displayText: string,
  value: 'all' | 'available' | 'almostOut' | 'out'
}

export function sortAsc(feed: Array<StoreGroceryStock>) {
  return [
    ...feed.filter(g => g.quantity === 0).sort(asc),
    ...feed.filter(g => g.quantity > 0 && isStockBelowRatio(g)).sort(asc),
    ...feed.filter(g => g.quantity > 0 && !isStockBelowRatio(g)).sort(asc)
  ]
}

export function sortAlphabetically(feed: Array<StoreGroceryStock>) {
  return [...feed.sort(alphabetically)]
}

export function filterFeed(feed: Array<StoreGroceryStock>, option: string) {
  if (option === 'available') {
    return [...feed.filter(g => g.quantity > 0)]
  } else if (option === 'almostOut') {
    return [...feed.filter(g => g.quantity > 0 && isStockBelowRatio(g))]
  } else if (option === 'out') {
    return [...feed.filter(g => g.quantity === 0)]
  }

  return feed
}

export function getFilterOptions(feed: Array<StoreGroceryStock>) : Array<FilterOption> {
  const allCount = feed.length
  const outCount = feed.filter(g => g.quantity === 0).length
  const almostOutCount = feed.filter(g => g.quantity > 0 && isStockBelowRatio(g)).length
  const availableCount = allCount - (outCount)
  
  return [
    { displayText: `Alla (${allCount})`, value: 'all' },
    { displayText: `Finns i skåp (${availableCount})`, value: 'available' },
    { displayText: `Snart slut (${almostOutCount})`, value: 'almostOut' },
    { displayText: `Slut (${outCount})`, value: 'out' }
  ]
}

const asc = (a: StoreGroceryStock, b: StoreGroceryStock) => {return a.quantity - b.quantity}
const alphabetically = (a: StoreGroceryStock, b: StoreGroceryStock) => a.name.localeCompare(b.name, 'sv')
