import React from "react"
import { useStoreContext } from "../contexts/store-context"
import ArrowIconMinimal from "./Icons/ArrowIconMinimal"

interface ISelectStoreProps {  
  className?: string
}

const SelectStore = ({ className }: ISelectStoreProps) => {
  const { storeState, storeReducer } = useStoreContext()

  const onChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value
    const newSelectedStore = storeState.availableStores.find((store) => store.id === value) ?? null
    if (newSelectedStore) {
      storeReducer.setSelectedStore(newSelectedStore)
    }
  }

  return (
    <>
      {storeState && storeState.selectedStore &&
      <div className="relative">
        <div className='select-icon flex items-center top-0 left-0 pointer-events-none'>
          <ArrowIconMinimal direction='down' size='15px' color='var(--select-header-color)'/>
        </div>
        <select onChange={onChange} defaultValue={storeState.selectedStore.id} className={`select-header cursor-pointer appearance-none pl-6${className ? ' ' + className : ''}`}>
          {storeState.availableStores.map((store) =>
            <option key={store.id} value={store.id}>
              {store.name}
            </option>
          )}
        </select>
      </div>
      }
    </>
  )
}

export default SelectStore