import React from 'react'
import SessionsList from '../components/SessionsList/SessionsList'

export default function Purchases() {
  return (
    <div className='md:pt-5'>
      <SessionsList />
    </div>
  )
}
