import React, { ReactNode, useCallback } from "react"

export interface IScrollTrapProps {
  children: ReactNode
}

export default function ScrollTrap ({ children }: IScrollTrapProps) {
  const scrollTrapRef = useCallback((node: Element | null) => {
    if (node === null) {
      return
    }

    if (node.children?.length !== 1) {
      console.error("ScrollTrap should have exactly one scrollable child node.")
      return
    }

    const scrollElement: Element = node.children[0]
    scrollElement.className = `${scrollElement.className} overscroll-contain`
    
    const resizeObserver = new ResizeObserver(() => {
      if (scrollElement.clientHeight > 0 && scrollElement.clientHeight == scrollElement.scrollHeight) {
        scrollElement.addEventListener('touchmove', preventScrollHandler, { passive: false })
        scrollElement.addEventListener('wheel', preventScrollHandler, { passive: false })
      }
      else {
        scrollElement.removeEventListener('touchmove', preventScrollHandler)
        scrollElement.removeEventListener('wheel', preventScrollHandler)
      }
    })
    resizeObserver.observe(node)
  }, [])

  const preventScrollHandler = (e: Event) => {
    e.preventDefault()
  }

  return (
    <div ref={scrollTrapRef}>
      {children}
    </div>
  )
}