import React, { ReactNode } from "react"

type IsMobileReducerType = (value: boolean | ((prev: boolean) => boolean)) => void

export interface DeviceContextState {
  deviceState: {
    isMobile: boolean,
  },
  deviceReducer: {
    setIsMobile: IsMobileReducerType,
  }
}

const DeviceContext = React.createContext<DeviceContextState | undefined>(undefined)

export function DeviceProvider({ children }: {children : ReactNode}) {
  const [isMobile, setIsMobile] = React.useState<boolean>(false)
  
  const state: DeviceContextState = React.useMemo(() => {
    return {
      deviceState: {
        isMobile,
      },
      deviceReducer: {
        setIsMobile
      }
    }
  }, [isMobile])

  return (
    <DeviceContext.Provider value={state}>
      {children}
    </DeviceContext.Provider>
  )
}

export function useDeviceContext () {
  const context = React.useContext(DeviceContext)
  if (!context) {
    throw new Error('useDeviceContext must be used within a DeviceProvider')
  }
  return context
}
