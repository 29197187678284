import React from 'react'
import { useUserContext } from '../contexts/user-context'
import { useNavigate } from "react-router-dom"
import { AUTH_KEY, AUTH_TYPE_KEY } from '../constants/localStorageKeys'

const Logout = () => {
  const navigate = useNavigate()
  const { userReducer } = useUserContext()
  const logoutUser = () => {
    localStorage.removeItem(AUTH_KEY)
    localStorage.removeItem(AUTH_TYPE_KEY)
    userReducer.resetUser()
    navigate('/login')
  }

  return (<button type="button" className='button header-logout-button' onClick={logoutUser}>Logga ut</button>)
}

export default Logout