import React from 'react'
import GroceryFeed from '../components/GroceryFeed/GroceryFeed'

export default function Groceries() {
  return (
    <div>
      <GroceryFeed />
    </div>
  )
}
