import React, { useEffect, useState, Suspense } from 'react'
import config from '../../config'
import { useStoreContext } from '../../contexts/store-context'
import { OverviewData } from '../../types/OverviewData'
import { SessionBrief } from '../../types/SessionBrief'
import { get, post } from '../../utils/http'
import SpinnerIcon from '../Icons/SpinnerIcon'
import TopOrderItems from '../TopOrderItems/TopOrderItems'
import './OverviewStatistics.scss'
import { OverviewStatisticsItem } from '../OverviewStatisticsItem/OverviewStatisticsItem'
import { Filter, getDefaultFilter, useFilterContext } from '../../contexts/filter-context'
import { getTodayText } from '../../utils/dateUtils'
import { useDeviceContext } from '../../contexts/device-context'

const Chart = React.lazy(() => import('../Chart/Chart'))

export function OverviewStatistics() {
  let interval: ReturnType<typeof setInterval>
  const { storeState } = useStoreContext()
  const [overview, setOverview] = useState<OverviewData | null>(null)
  const [todaySessions, setTodaySessions] = useState<Array<SessionBrief> | null> ()
  const [loading, setLoading] = useState(false)
  const { filterState, filterReducer } = useFilterContext()
  const { deviceState } = useDeviceContext()

  const startIntervall = () => {
    interval = setInterval(() => {
      fetchOverviewData()
    }, 10000)
  }

  const fetchOverviewData = async () => {
    setLoading(true)
    const startTime = new Date(new Date().setHours(0,0,0,0)).toISOString()
    const endTime = new Date().toISOString()
    const sessionFilterBody = {
      storeId: storeState.selectedStore?.id,
      from:  startTime,
      to: endTime,
    }

    const promises = await Promise.allSettled([
      get<OverviewData>(`${config.STORE_API_BASE_URL}/v1/monitor/overview/${storeState.selectedStore?.id}?startTime=${startTime}&endTime=${endTime}`),
      post<typeof sessionFilterBody, Array<SessionBrief>>(`${config.STORE_API_BASE_URL}/v2/session/`, sessionFilterBody)
    ])

    if(promises[0].status === 'fulfilled') {
      setOverview(promises[0].value)
    }
    if(promises[1].status === 'fulfilled') {
      setTodaySessions(promises[1].value)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (storeState.selectedStore?.id) {
      fetchOverviewData()
      if (interval) {
        clearInterval(interval)
      }
      startIntervall()
    }
    return () => {
      clearInterval(interval)
    }
  }, [storeState.selectedStore])

  const setCompletedPurchasesFilter = () => {    
    const newFilter: Filter = getDefaultFilter()
    newFilter.storeId = filterState.filter.storeId
    filterReducer.setFilter(newFilter)
  }

  const setMissingPaymentsFilter = () => {
    const newFilter: Filter = getDefaultFilter()
    newFilter.storeId = filterState.filter.storeId
    newFilter.paymentMissing = true
    filterReducer.setFilter(newFilter)
  }

  return (
    <div>
      <>
        {overview &&
        <>
          {deviceState.isMobile && <div className='overview-statistics-subtitle select-none mb-3'>Idag {getTodayText()}</div>}
          <div className='overview-statistics-grid mb-6'>
            <OverviewStatisticsItem className='overview-statistics-visitors' linkTo='/live-feed' count={overview.visitors} text='I butik' />
            <OverviewStatisticsItem className='overview-statistics-shopping' linkTo='/live-feed' count={overview.shopping} text='Handlar' />
            <OverviewStatisticsItem className='overview-statistics-paying' linkTo='/live-feed' count={overview.paying} text='Betalar' />
            <OverviewStatisticsItem className='overview-statistics-completed-purchases' linkTo='/sessions' count={overview.completedPurchases} text='Genomförda köp' markerColor='#7DB66D' onClick={setCompletedPurchasesFilter} />
            <OverviewStatisticsItem className='overview-statistics-sessions-missing-payment' linkTo='/sessions' count={overview.sessionsMissingPayment} text='Saknar betalning' markerColor='#E17366' onClick={setMissingPaymentsFilter} />
          </div>
        </>
        }
        <div className='md:grid grid-rows-2 lg:grid-rows-1 lg:grid-cols-5 gap-6'>
          {todaySessions && 
          <div className='lg:col-span-2'>
            <TopOrderItems sessions={todaySessions}/>
          </div>
          }
          {overview && overview.hourlyStatistics && 
          <div className='lg:col-span-3 mt-6 md:mt-0'>
            <div className='overview-statistics-subtitle select-none mb-3'>Aktivitet</div>
            <div className='overview-statistics-chart-container mobile-scroll-horizontal pb-4'>
              <Suspense fallback={<div>Loading Chart...</div>}>
                <Chart hourlyStatistics={overview?.hourlyStatistics} />
              </Suspense>
            </div>
          </div>
          }
        </div>
      </>
      <div className='grid place-content-center'>
        {loading && <SpinnerIcon />}
      </div>
    </div>
  )
}
