import React from 'react'

export default function ArrowIconMinimal({ direction, size = '20px', color = 'var(--color-accent-red)' }: { direction: 'up' | 'right' | 'down' | "left", size?: string, color?: string }) {
  const rotate = (direction: 'up' | 'right' | 'down' | "left") => {
    switch (direction) {
    case 'up':
      return 'rotate(-90deg)'
    case 'right':
      return 'rotate(0)'
    case 'down':
      return 'rotate(90deg)'
    case 'left':
      return 'rotate(180deg)'
    default:
      return 'rotate(0)'
    }
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 20 20`} fill="none" style={{ transform: `${rotate(direction)}` }}>
      <g clipPath="url(#clip0_1015_497)">
        <path d="M6.72358 20C7.18339 20 7.64205 19.8129 7.98117 19.4424L15.5336 11.2072C16.1555 10.5284 16.1555 9.47193 15.5336 8.79312L7.98117 0.557954C7.33053 -0.1538 6.24078 -0.189093 5.54416 0.477955C4.84985 1.145 4.81536 2.26263 5.46714 2.97203L11.9114 10.0002L5.46714 17.0283C4.81536 17.7377 4.84985 18.8553 5.54416 19.5224C5.87753 19.8412 6.30055 20 6.72358 20Z" fill={color}/>
      </g>
      <defs>
        <clipPath id="clip0_1015_497">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}
